<template>
	<main>
		<div class="column__wrapper">
			<div class="column column__left">
				<AccountMenu />
			</div>
			<div class="column column__right">
				<template v-if="idDeliveryDefaultAddress || idInvoiceDefaultAddress">
					<h3>Mes adresses par défaut</h3>
					<div class="address__default">
						<div class="address__default__column" v-if="idInvoiceDefaultAddress">
							<h4>Adresse de facturation</h4>
							<div class="address__default__item" >
								<p class="name">{{ invAdd.name }}</p>
								<p>{{ invAdd.street }} {{ invAdd.street2 }}</p>
								<p>{{ invAdd.zip }} {{ invAdd.city }}</p>
								<p>{{ invAdd.country }}</p>
								<p>{{ invAdd.phone }}</p>
							</div>
						</div>
						<div class="address__default__column" v-if="idDeliveryDefaultAddress">
							<h4>Adresse de livraison</h4>
							<div class="address__default__item">
								<p class="name">{{ delivAdd.name }}</p>
								<p>{{ delivAdd.street }} {{ delivAdd.street2 }}</p>
								<p>{{ delivAdd.zip }} {{ delivAdd.city }}</p>
								<p>{{ delivAdd.country }}</p>
								<p>{{ delivAdd.phone }}</p>
							</div>
						</div>
					</div>
				</template>

				<template v-if="listAddress.length > 0">
					<h3>Mon carnet d'adresses</h3>
					<h4>La liste de vos différentes adresses</h4>
					<ul class="address__list">
						<li v-for="address in listAddress" class="address__list__row">
							<div class="item">
								<div class="item__column">
									<div class="item__text">
										<p class="name">{{ address.name }} <i v-if="!address.parent_id"> (compte principal)</i></p>
										<p>{{ address.street }} {{ address.zip }} {{ address.city }}</p>
                    <p v-if="!address.street"> <i>adresse incomplète... Cliquez sur modifier pour compléter votre adresse. </i></p>
									</div>
									<div class="item__buttons">
										<router-link :to="{name: 'EditAddress', params: {id: address.id}}" class="button">
											modifier
										</router-link>
										<button  aria-label="delete" v-if="address.parent_id" type="button" class="button" @click="deleteAddress(address.id)">supprimer
										</button>
									</div>
								</div>
								<div class="item__column radio invoice">
									<label :for="`default-invoice-${address.id}`">Adresse de
										facturation<span> par défaut</span></label>
									<input
										@click="defaultInvoiceAddress(address.id)"
										type="radio"
										class="input"
										:name="`default-invoice-${address.id}`"
										:id="`default-invoice-${address.id}`"
										:value="address.id"
										v-model="idInvoiceDefaultAddress"
									/>

								</div>
								<div class="item__column radio delivery">
									<label :for="`default-shipping-${address.id}`">Adresse de
										livraison<span> par défaut</span></label>
									<input
										@click="defaultDelieryAddress(address.id)"
										type="radio"
										class="input"
										:name="`default-shipping-${address.id}`"
										:id="`default-shipping-${address.id}`"
										:value="address.id"
										v-model="idDeliveryDefaultAddress"
									/>
								</div>
							</div>
						</li>
					</ul>
				</template>

				<div>
					<router-link :to="{name: 'AddAddress'}" class="button full">Ajouter une adresse</router-link>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
const AccountMenu = () => import ("@/components/AccountMenu.vue")
import {mapGetters} from "vuex";
import {getAPI} from "@/services/axios-api";
import {i18n} from "@/main";

export default {
	name: "Address",
	components: {AccountMenu},
	computed: {
		...mapGetters('auth', ['getUserId']),
	},
	mounted() {
    this.getRespartner();
    // this.getUser();
	},
	data() {
		return {
			listAddress: [],
			idDeliveryDefaultAddress: null,
			idInvoiceDefaultAddress: null,
			delivAdd: [],
			invAdd: [],
			activeLink: ''
		}
	},
  methods: {
    getDelivAdd(idDeliveryDefaultAddress) {
      const deliv = this.listAddress.find(e => e.id === idDeliveryDefaultAddress);
      if (deliv) return this.delivAdd = deliv
    },
    getInvAdd(idInvoiceDefaultAddress) {
      const invoice = this.listAddress.find(e => e.id === idInvoiceDefaultAddress);
      if (invoice) return this.invAdd = invoice
    },
		async getRespartner() {
			await getAPI
				.get('/respartner/')
				.then(response => {
					this.listAddress = response.data;
          // this.listAddress.find(e => e.parent_id === null);
				})
				.catch(errors => {
				})
      await getAPI
            .get('account/profile/' + this.getUserId + '/')
            .then(response => {
              if (response.data.delivery_address) {this.idDeliveryDefaultAddress = response.data.delivery_address;
              } else {
                this.idDeliveryDefaultAddress = response.data.odoo_user_id;}
              if (response.data.invoice_address) {this.idInvoiceDefaultAddress = response.data.invoice_address;
              } else {
                this.idInvoiceDefaultAddress = response.data.odoo_user_id;
              }
            })
      this.getDelivAdd(this.idDeliveryDefaultAddress);
      this.getInvAdd(this.idInvoiceDefaultAddress);

		},
		async getUser() {
      await getAPI
          .get('account/profile/' + this.getUserId + '/')
          .then(response => {
            if (response.data.delivery_address) {this.idDeliveryDefaultAddress = response.data.delivery_address;
            } else {
              this.idDeliveryDefaultAddress = response.data.odoo_user_id;}
            if (response.data.invoice_address) {this.idInvoiceDefaultAddress = response.data.invoice_address;
            } else {
              this.idInvoiceDefaultAddress = response.data.odoo_user_id;
            }
          })
		},
		async defaultDelieryAddress(partnerID) {
			this.getDelivAdd(partnerID);
			await getAPI
				.post('/account/profile/default/address/', {'delivery_address': partnerID})
		},
		async defaultInvoiceAddress(partnerID) {
			this.getInvAdd(partnerID);
			await getAPI
				.post('/account/profile/default/address/', {'invoice_address': partnerID})
		},
		async deleteAddress(partnerID) {
			await getAPI
				.post('/respartner/delete/', {'id': partnerID})
				.then(response => {
					this.getRespartner()
          this.$toast.open({
            message: i18n.t('delete_partner_success'),
            type: 'success',
          });
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.address__default {
	@include flex(flex-start, flex-start, row, wrap, $gap: 35px);
	width: 100%;
	@media screen and (max-width: 575.99px) {
		gap: 10px
	}

	&__column {
		@media screen and (max-width: 575.99px) {
			width: 100%;
		}

		h4 {
			margin-bottom: 5px;
			font-size: 17px;
		}
	}

	&__item {
		font-size: 15px;
		font-weight: 600;
		background: #F9F9F9;
		border: 1px solid #BDBDBD;
		border-radius: 5px;
		padding: 10px 10px 20px;

		.name {
			color: $Brown;
			font-size: 16px;
		}
	}
}

.address__list {
	@include flex(center, center, column, $gap: 20px);
	width: 100%;

	&__row {
		@include flex(space-between, center, $gap: 10px);
		width: 100%;

		.item {
			@include flex(space-between, flex-start, row);
			background: #F9F9F9;
			border: 1px solid #BDBDBD;
			border-radius: 5px;
			padding: 10px;
			flex: 1;
			position: relative;

			@media screen and (max-width: 1199.99px) {
				flex-wrap: wrap;
				gap: 20px;
			}

			&__text {
				font-weight: 600;
				flex: 1;
				margin-bottom: 10px;

				.name {
					color: $Brown;
					font-size: 16px;
				}
			}

			&__buttons {
				@include flex(flex-start, flex-start, $gap: 10px);
				width: 100%;

				@media screen and (max-width: 575.99px) {
					flex-wrap: wrap;
				}
			}

			&__radios {
				@include flex(space-between, center, $gap: 10px);
			}

			.radio {
				@include flex(flex-start, center, column,  $gap: 10px);


				@media screen and (max-width: 1199.99px) {
					flex: 0 0 100%;
					justify-content: flex-end;
					flex-direction: row-reverse;
					align-items: center;
					gap: 5px;
				}

				&.invoice {
					--color: #{$Blue};
					margin-left: auto;
				}

				&.delivery {
					--color: #{$Brown}
				}

				label {
					position: relative;
					font-weight: 600;

					@media screen and (min-width: 1200px) {
						translate: 10px calc(-100% - 10px);
						border-left: solid 2px $White;
						background-color: var(--color);
						font-weight: 500;
						color: $White;
						visibility: hidden;
						opacity: 0;
						flex: auto;
						padding: 2px 10px;
						white-space: nowrap;

						&::after {
							@include position(100%, 50%);
							translate: -50%;
							height: 20px;
							width: 30px;
							border-left: solid 15px $t;
							border-right: solid 15px $t;
							border-top: 15px solid var(--color);
							border-bottom: none;
						}
						span {
							display: none;
						}
					}
				}

				input {
					border-color: var(--color);
					@media screen and (min-width: 1200px) {
						translate: 10px;
					}

					&:checked {
						background-color: var(--color);
					}

					&:focus {
						outline-color: var(--color);
					}
				}
			}
		}

		&:first-child .radio label {
			visibility: visible;
			opacity: 1;
		}

	}
}
</style>